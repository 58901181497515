import { render, staticRenderFns } from "./AgentDetail.vue?vue&type=template&id=751e5ba8&scoped=true&"
import script from "./AgentDetail.vue?vue&type=script&lang=js&"
export * from "./AgentDetail.vue?vue&type=script&lang=js&"
import style0 from "./AgentDetail.vue?vue&type=style&index=0&id=751e5ba8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "751e5ba8",
  null
  
)

export default component.exports