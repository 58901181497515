import { viewsetActions } from '@/service/plugin';

const definitions = viewsetActions(
  'definitions',
  {
    basePath: '/api/lms/',
    extraActions: {
      enable: { path: 'enable', method: 'post', detail: true },
      disable: { path: 'disable', method: 'post', detail: true },
    },
  },
);
const definitionsRequirements = viewsetActions(
  'definition-requirements',
  {
    basePath: '/api/lms/',
    extraActions: {
      enable: { path: 'enable', method: 'post', detail: true },
      disable: { path: 'disable', method: 'post', detail: true },
    },
  },
);

export default {
  definitions,
  definitionsRequirements,
};
