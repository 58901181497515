<template>
  <div>
    <v-list v-if="basicDetails.id && rtwDetails.id">
      <v-row>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.forename }}</v-list-item-title>
              <v-list-item-subtitle>Forename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="basicDetails.middlename">
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.middlename }}</v-list-item-title>
              <v-list-item-subtitle>Middlename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.surname }}</v-list-item-title>
              <v-list-item-subtitle>Surname</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.nationality_display }}</v-list-item-title>
              <v-list-item-subtitle>Nationality</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ rtwDetails.verification_method_display }}
              </v-list-item-title>
              <v-list-item-subtitle>Verification Method</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ rtwDetails.checked_by.first_name }}
                {{ rtwDetails.checked_by.last_name }}
              </v-list-item-title>
              <v-list-item-subtitle>Checked by</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ longDatetime(rtwDetails.checked_at) }}
              </v-list-item-title>
              <v-list-item-subtitle>Checked at</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ rtwDetails.state_display }}</v-list-item-title>
              <v-list-item-subtitle>Result</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>
    <v-divider v-if="documents.length > 0" />
    <v-row
      v-for="doc in documents"
      :key="doc.id"
      class="mt-4"
    >
      <v-col cols="6">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ doc.document_type.name }}</v-list-item-title>
              <v-list-item-subtitle>Type</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="6">
        <v-card
          outlined
          class="pa-2"
        >
          <PdfThumbnail
            v-if="doc.extension === 'pdf'"
            class="doc-image"
            :src="doc.content_url"
            @click="showDocument(doc)"
          />
          <v-img
            v-else
            class="doc-image"
            :src="doc.content_url"
            @click="showDocument(doc)"
          />
        </v-card>
        <v-btn
          class="mt-4 float-right"
          :href="getDownloadLink(doc)"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon
            left
          >
            mdi-open-in-new
          </v-icon>
          Open
        </v-btn>
      </v-col>
    </v-row>
    <DocumentViewer
      v-if="docViewer.doc.id"
      :key="`viewer-${docViewer.doc.id}`"
      v-model="docViewer.show"
      :src="docViewer.doc.content_url"
      :title="docViewer.doc.document_type.name"
      :extension="docViewer.doc.extension"
    />
  </div>
</template>

<script>
import PdfThumbnail from '@/components/common/PdfThumbnail.vue';
import DocumentViewer from '@/components/common/DocumentViewer.vue';
import { longDatetime } from '@/filters/index';

export default {
  name: 'RtwDetails',
  components: {
    PdfThumbnail,
    DocumentViewer,
  },
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    basicDetails: {},
    rtwDetails: {},
    documents: [],
    docViewer: {
      show: false,
      doc: {},
    },
  }),
  created() {
    this.getBasicDetails();
    this.getRtwDetails();
    this.getDocuments();
  },
  methods: {
    longDatetime,
    async getBasicDetails() {
      const resp = await this.$service.product.caseBasicDetails.retrieve(
        this.caseActivityDetails.case.id,
      );
      if (resp.status === 200) {
        this.basicDetails = resp.data;
      }
    },
    async getRtwDetails() {
      const resp = await this.$service.product.caseRtwDetails.retrieve(
        this.caseActivityDetails.case.id,
      );
      if (resp.status === 200) {
        this.rtwDetails = resp.data;
      }
    },
    async getDocuments() {
      const ca = this.caseActivityDetails;
      const resp = await this.$service.product.caseDocuments.list(
        { params: { case: ca.case.id, case_activity: ca.id } },
      );
      this.documents = resp.data;
    },
    getDownloadLink(doc) {
      return `/api/case-documents/${doc.id}/download/?inline=true`;
    },
    showDocument(doc) {
      this.docViewer.doc = doc;
      this.docViewer.show = true;
    },
  },
};
</script>

<style scoped lang="scss">
.doc-image:hover {
  cursor: pointer;
}
</style>
