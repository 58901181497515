import { viewsetActions, request } from '@/service/plugin';

const osDatahubCache = {
  token: null,
  expiry: null,
};

const serviceActions = {
  // Driver Data
  changeNumber: { path: 'actions/change_number', method: 'post', detail: true },
  confirmNotFound: { path: 'actions/confirm_not_found', method: 'post', detail: true },
};
const opsExtraActions = {
  start: { path: 'start', method: 'post', detail: true },
  requestCustomerDecision: { path: 'request_customer_decision', method: 'post', detail: true },
  updateAndRequestCustomerDecision: {
    path: 'update_and_request_customer_decision',
    method: 'post',
    detail: true,
  },
  notes: { path: 'notes', method: 'post', detail: true },
  notes_history: { path: 'notes_history', method: 'get', detail: true },
  assign: { path: 'assign', method: 'post', detail: false },
  clearAssignee: { path: 'clear-assignee', method: 'post', detail: false },
  enableOpsAttentionByEnumber: { path: 'enable-ops-attention-by-e-number', method: 'post', detail: false },
  opsServicedStageRetrieve: { path: 'stage', method: 'get', detail: true },
  opsServicedStageProgress: { path: 'stage', method: 'post', detail: true },
  ...serviceActions,
};
const opsCaseActivities = viewsetActions('case-activities', { basePath: '/api/ops/', extraActions: opsExtraActions });

const idvExtraActions = {
  verify: { path: 'verify', method: 'post', detail: true },
  unverify: { path: 'unverify', method: 'post', detail: true },
  countersign: { path: 'countersign', method: 'post', detail: true },
  collector: { path: 'collector', method: 'get', detail: true },
  conditionalReplaceConfig: { path: 'conditional-replace-config', method: 'post', detail: true },
  setManualIdv: { path: 'set-manual-idv', method: 'post', detail: true },
};
const opsIdvCaseActivities = viewsetActions('idv-case-activities', { basePath: '/api/ops/', extraActions: idvExtraActions });

const opsCaseAddresses = {
  ...viewsetActions('case-addresses', {
    basePath: '/api/ops/',
    extraActions: { osFakeLookup: { path: 'os-fake-lookup', method: 'get', detail: false } },
  }),
  osDatahubToken: async (options) => {
    const now = new Date();
    if (!osDatahubCache.expiry || now >= osDatahubCache.expiry) {
      const path = '/api/ops/case-addresses/os-datahub-token/';
      const resp = await request('post', path, { ...options, skipSuccessMessage: true });
      if (resp.status === 200) {
        osDatahubCache.token = resp.data.access_token;
        osDatahubCache.expiry = new Date(now.getTime() + 1000 * resp.data.expires_in);
      }
    }
    return osDatahubCache.token;
  },
};

export default {
  caseActivities: opsCaseActivities,
  caseAddresses: opsCaseAddresses,
  idvCaseActivities: opsIdvCaseActivities,
  caseBasicDetails: viewsetActions('case-basic-details', { basePath: '/api/ops/' }),
  caseBirthDetails: viewsetActions('case-birth-details', { basePath: '/api/ops/' }),
  caseOtherNames: viewsetActions('case-other-names', { basePath: '/api/ops/' }),
  caseDrivingLicences: viewsetActions('case-driving-licences', { basePath: '/api/ops/' }),
  casePassports: viewsetActions('case-passports', { basePath: '/api/ops/' }),
  caseDocuments: viewsetActions('case-documents', { basePath: '/api/ops/' }),
};
