<template>
  <div v-if="caseActivityDetails.id">
    <template v-if="activityData.topup_payment_url && !applicationDetails.status">
      <p>Awaiting top-up payment</p>
      <p class="mb-8">
        Payment link:
        <a :href="activityData.topup_payment_url" target="_blank" rel="noopener noreferrer">
          {{ activityData.topup_payment_url }}</a>
      </p>
    </template>
    <v-row>
      <v-col
        v-if="activityData.applicant_details"
        cols="12"
        sm="4"
      >
        <span
          class="overline"
        >
          Applicant Details
        </span>
        <p>
          <span class="body-2 text--secondary">Name:</span>
          <span> {{ applicantName }}</span>
          <br>
          <span class="body-2 text--secondary">Date of birth:</span>
          <span> {{ activityData.applicant_details.date_of_birth }}</span>
          <br>
          <span class="body-2 text--secondary">Place of birth:</span>
          <span> {{ activityData.applicant_details.place_of_birth }}</span>
          <br>
        </p>
      </v-col>
      <v-divider
        v-if="activityData.applicant_details"
        vertical
        inset
        class="d-none d-sm-flex"
      />
      <v-col
        cols="12"
        sm="4"
      >
        <span class="overline">Application Details</span>
        <p v-if="applicationDetails.status === 'OK'">
          <span class="body-2 text--secondary">Status</span>
          <span> {{ applicationDetails.status }}</span>
          <br>
          <span class="body-2 text--secondary">DBS Reference</span>
          <span> {{ applicationDetails.form_reference }}</span>
          <br>
          <br>
          <span class="body-2 text--secondary">Received Date</span>
          <span> {{ applicationDetails.received_date }}</span>
          <template v-if="applicationDetails.police_national_computer_search_date">
            <br>
            <span class="body-2 text--secondary">Police NC Search Date</span>
            <span> {{ applicationDetails.police_national_computer_search_date }}</span>
          </template>
          <template v-if="applicationDetails.barred_lists_search_date">
            <br>
            <span class="body-2 text--secondary">
              DBS Children, DBS Adults and List 99 Search Date
            </span>
            <span> {{ applicationDetails.barred_lists_search_date }}</span>
          </template>
          <template v-if="applicationDetails.police_records_held_date">
            <br>
            <span class="body-2 text--secondary">Records Held by the Police Search Date</span>
            <span> {{ applicationDetails.police_records_held_date }}</span>
          </template>
          <template v-if="applicationDetails.certificate_printed_date">
            <br>
            <span class="body-2 text--secondary">Certificate Printed Date</span>
            <span> {{ applicationDetails.certificate_printed_date }}</span>
          </template>
        </p>
        <p v-else>
          <span class="font-italic text--secondary">DBS Receipts not yet available</span>
        </p>
      </v-col>
      <v-divider
        vertical
        inset
        class="d-none d-sm-flex"
      />
      <v-col
        cols="12"
        sm="4"
      >
        <span class="overline">Disclosure Result</span>
        <template v-if="disclosureResult.status">
          <p>
            <span class="body-2 text--secondary">Status</span><br>
            <span class="font-weight-bold">{{ disclosureResult.status }}</span>
          </p>
          <p>
            <span class="body-2 text--secondary">Number</span>
            <span> {{ disclosureResult.number }}</span>
            <br>
            <span class="body-2 text--secondary">Issue date</span>
            <span> {{ disclosureResult.issue_date }}</span>
          </p>
          <p>
            <span class="body-2 text--secondary mr-2">Note</span>
            <span>This is not a certificate issued by the DBS</span>
          </p>
          <p v-if="updateServiceStatus">
            <span class="body-2 text--secondary">Update Service</span>
            <span> {{ updateServiceStatus }}</span>
          </p>
        </template>
        <template v-else>
          <p>
            <span class="font-italic text--secondary">DBS Results not yet available</span>
          </p>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'EbulkDetails',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activityData() {
      return this.caseActivityDetails.result_data;
    },
    applicantName() {
      const details = this.activityData.applicant_details || {};
      return [details.forenames, details.surname].filter(Boolean).join(' ');
    },
    applicationDetails() {
      return this.activityData.application_details || {};
    },
    disclosureResult() {
      return this.activityData.disclosure_result || {};
    },
    updateServiceStatus() {
      const matchStatuses = ['BLANK_NO_NEW_INFO', 'NON_BLANK_NO_NEW_INFO', 'NEW_INFO'];
      const updateService = this.activityData.update_service;
      let status = null;
      if (updateService && matchStatuses.includes(updateService.status)) {
        status = 'Registered';
      } else if (updateService && this.caseActivityDetails.state === 'ca-in-progress') {
        status = 'Pending';
      } else if (updateService) {
        status = 'Not registered';
      }
      return status;
    },
  },
};
</script>
